import React, {FunctionComponent} from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {FooterContent} from '../types/FooterContent';

interface NotFoundPageProps {
  pageContext: {
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
  };
}

const NotFoundPage: FunctionComponent<NotFoundPageProps> = ({
  pageContext: {contentfulFooterContent, customerPortalBaseUrl},
}) => (
  <Layout
    {...contentfulFooterContent}
    customerPortalBaseUrl={customerPortalBaseUrl}
  >
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
